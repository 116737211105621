<template>
    <div class="body-container">
        <BannerSection title="Obtén tu préstamo por tu automóvil <br class='d-none d-lg-block'/> con Ibancar México" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        ¿Necesitas financiamiento rápido pero no quieres renunciar a tu medio de transporte? En Ibancar
                        México, te ofrecemos la solución perfecta con nuestros préstamos por automóvil. Con más de 6
                        años de experiencia en España, hemos extendido nuestra experticia al mercado mexicano,
                        ofreciendo soluciones financieras rápidas, seguras y confiables. Miles de clientes satisfechos
                        respaldan nuestra trayectoria, asegurándote un servicio de calidad superior.
                    </p>

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funciona el préstamo por automóvil?</h2>
                        <br>

                    </span>

                    <p class="mb-0">
                        El <span class="bold-text">préstamo por automóvil de Ibancar
                        </span>simplifica el acceso a financiamiento para quienes necesitan liquidez sin sacrificar su
                        medio de transporte esencial. Este enfoque único permite a los titulares de vehículos usar su
                        coche como garantía para un préstamo, manteniendo al mismo tiempo el derecho de uso, lo cual
                        significa que puedes seguir conduciendo tu vehículo para tus actividades diarias mientras
                        gestionas tus necesidades financieras.

                    </p>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Cómo se estructura este tipo de préstamo?</h3>
                        <br>
                    </span>
                    <ol>
                        <li>
                            <p class="mb-0 bold-text">
                                Valoración del vehículo:
                            </p>
                            <span>El proceso comienza con una evaluación detallada de tu auto para determinar su valor
                                en el
                                mercado actual. Este paso es crucial, ya que el monto del préstamo se basará en esta
                                valoración, asegurando que el préstamo refleje de manera justa el valor de tu
                                vehículo.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Documentación y aprobación:
                            </p>
                            <span>Se te solicitará proporcionar cierta documentación, como identificación oficial,
                                comprobante de ingresos y documentos del vehículo, para proceder con la solicitud de
                                préstamo. La transparencia y rapidez son claves en este proceso, buscando aprobar tu
                                préstamo de manera eficiente y sin demoras innecesarias.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Firma del acuerdo:
                            </p>
                            <span>Una vez aprobado, se formaliza el préstamo mediante un contrato que detalla los
                                términos y
                                condiciones, incluyendo la tasa de interés, el calendario de pagos y la duración del
                                préstamo. Este paso asegura que todas las partes tengan claridad sobre sus obligaciones
                                y
                                derechos.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Desembolso del préstamo:
                            </p>
                            <span>Con el contrato firmado, el monto acordado se te entregará en efectivo o se depositará
                                en
                                tu cuenta bancaria. Este proceso se realiza de forma rápida para que puedas acceder al
                                dinero cuando más lo necesitas.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Continúa usando tu automóvil:
                            </p>
                            <span>Lo que distingue a este tipo de préstamo es la posibilidad de seguir utilizando tu
                                auto. A
                                diferencia de otros tipos de empeños o préstamos con garantía, donde el bien queda
                                retenido
                                por el prestamista, Ibancar te permite mantener la posesión y uso de tu vehículo. Esta
                                flexibilidad te permite manejar tus compromisos financieros sin afectar tu movilidad o
                                rutina diaria.</span>
                        </li>
                    </ol>

                    <br>
                </div>
            </div>
        </div>
        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Ventajas de elegir Ibancar para tu préstamo por automóvil</h2>
                        <br>

                    </span>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Flexibilidad:
                                </span>Continúa usando tu vehículo mientras tienes el préstamo.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Rapidez:
                                </span>Obtén tu préstamo de forma rápida y sin procesos burocráticos tediosos.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Confianza:
                                </span>Benefíciate de la experiencia y satisfacción probada de nuestros clientes en
                                España,
                                ahora en México.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Transparencia:
                                </span> Condiciones claras y justas desde el principio.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Testimonios de clientes satisfechos:
                                </span>No solo te lo decimos nosotros, escucha a quienes han vivido la experiencia
                                Ibancar y
                                cómo hemos contribuido a mejorar su situación financiera sin complicar su vida
                                cotidiana.

                            </p>
                        </li>
                    </ul>

                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">¿Listo para comenzar?</h3>
                        <br>

                    </span>
                    <p class="mb-0">
                        Si estás enfrentando dificultades económicas y necesitas una solución rápida y confiable,
                        los
                        préstamos
                        por automóvil de Ibancar son la elección perfecta. Contáctanos hoy mismo y descubre cómo
                        podemos
                        ayudarte a obtener el financiamiento que necesitas, con la tranquilidad de mantener tu
                        vehículo
                        contigo.

                    </p>

                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamoPorAutomovilConIbancar',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos por automovil | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Accede a financiamiento rápido y flexible con préstamos por tu automóvil. Sin necesidad de dejar tu carro, con procesos transparentes y atención personalizada. ¡Descubre las mejores opciones para ti!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos por automovil'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/obten-tu-prestamo-por-automovil-con-ibancar', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>